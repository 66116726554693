
<script lang="ts" setup>
withDefaults(defineProps<{
  prefix?: string
  name: string
  color?: string
  size?: 'sm' | 'md' | 'lg'
  title?: string
}>(), { prefix: 'icon',size: 'sm', })

const symbolId = computed(() => `#${__props.prefix}-${__props.name}`)

const sizeMap = {
  sm: '16px',
  md: '20px',
  lg: '24px',
}

const height = computed(() => sizeMap[__props.size])
const width = computed(() => sizeMap[__props.size])
</script>

<template>
  <svg
    aria-hidden="true"
    class="v2-icon"
    :class="[
      `v2-svg-${name}`,
      color && `text-${color}`,
    ]"
    :name="name"
    :aria-label="name"
    :style="{
      width,
      height,
      stroke: 'currentcolor',
      fill: 'currentcolor',
      maxWidth: 'initial',
    }"
  >
    <title v-if="title">{{ title }}</title>
    <use :href="symbolId"/>
  </svg>
</template>
